import $ from 'jquery'
import animateScrollTo from 'animated-scroll-to'
import slickCarousel from 'slick-carousel'

var typerText = "Worry-free Web and Mobile App Solutions";
var i = 0;


function startTyping() {

  var curr = $(".typer1").text();
  console.log("type", typerText.charAt(i));
  if (i < typerText.length) {
    $(".typer1").text(curr + "" + typerText.charAt(i));
    i++;

    if(i < typerText.length) {
      setTimeout(startTyping, 25);
    }else {
      setTimeout(startTyping2, 50);
    }
    
  }
}

var typerText2 = "Look no further for your next reliable and cost-effective software.";
var j = 0;


function startTyping2() {

  var curr = $(".typer2").text();
  console.log("type", typerText2.charAt(j));
  if (j < typerText2.length) {
    $(".typer2").text(curr + "" + typerText2.charAt(j));
    j++;

    if(j < typerText2.length) {
      setTimeout(startTyping2, 25);
    }else {
      $('#start').addClass('active').fadeOut().fadeIn();
    }
    
  }
}

$(document).ready(()=>{
  // Header bg/border
  if(window.scrollY < 100){
    $('#Header').removeClass("scrolled");
  }else{
    $('#Header').addClass("scrolled");
  }

  startTyping();
  
  $("#portfolio-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    // fade: true,
    dots: true,
    dotsClass: 'slider-dots',
    autoplay: true,
    autoplaySpeed: 3000,
    // asNavFor: '#portfolio-slider-nav',
    prevArrow: '<button class="slider-prev"><i class="fa-solid fa-2x fa-angle-left"></i></button>',
    nextArrow: '<button class="slider-next"><i class="fa-solid fa-2x fa-angle-right"></i></button>'
  });
  // $("#portfolio-slider-nav").slick({
  //   dots: false,
  //   arrows: false,
  //   slidesToShow: 3,
  //   centerMode: true,
  //   // autoplay: true,
  //   asNavFor: '#portfolio-slider',
  //   autoplaySpeed: 2000,
  //   infinite: true    
  // });

  $(".scroller").click((event)=>{
    event.preventDefault();
    $("#MainMenu li a").removeClass("active");
    $(event.currentTarget).addClass("active");

    $('.menu-container').removeClass("show");
    $("body").removeClass("noscroll");
    
    let options = {
      verticalOffset: -70
    }
    animateScrollTo(document.querySelector(event.currentTarget.attributes['data-target'].value),options);
  });

  $(".modal-button").click((event)=>{
    event.preventDefault();
    console.log("event---", event)
    let target = event.currentTarget.attributes['data-target'].value;

    $(target).addClass('show');
    $('body').addClass('noscroll')
  });

  $(".modal-close").click((event)=>{
    event.preventDefault();
    $('.modal-overlay').removeClass("show");
    $("body").removeClass("noscroll");
  });

  $(".mobile-menu-open").click((event)=>{
    event.preventDefault();
    $('.menu-container').addClass("show");
    $("body").addClass("noscroll");
  });
  $(".mobile-menu-close").click((event)=>{
    event.preventDefault();
    $('.menu-container').removeClass("show");
    $("body").removeClass("noscroll");
  });

  $(".copy-year").text(new Date().getFullYear());
})


// Scroll spy
  //
  // var section = document.querySelectorAll(".section");
  // var sections = {};
  // var i = 0;
  //
  // Array.prototype.forEach.call(section, function(e) {
  //   console.log(e);
  //   sections[e.id] = e.offsetTop;
  // });
  //
  // console.log("SECTIONSS---", sections)
  //
  // window.onscroll = function() {
  //   var scrollPosition = document.documentElement.scrollTop;
  //   console.log("SCROLL---", scrollPosition);
  //   for (i in sections) {
  //     if (sections[i] <= scrollPosition) {
  //       console.log(i, sections[i], window.innerHeight)
  //       $("#MainMenu li a").removeClass("active");
  //       $('#MainMenu li a[data-target*=' + i + ']').addClass('active');
  //     }
  //   }
  //
  //   // Header bg/border
  //   if(scrollPosition < 100){
  //     $('#Header').removeClass("scrolled");
  //   }else{
  //     $('#Header').addClass("scrolled");
  //   }
  // };

  document.addEventListener('DOMContentLoaded', function(){

  // grab the sections (targets) and menu_links (triggers)
  // for menu items to apply active link styles to
  const sections = document.querySelectorAll(".section");
  const menu_links = document.querySelectorAll("#MainMenu li a");

  // functions to add and remove the active class from links as appropriate
  const makeActive = (link) => menu_links[link].classList.add("active");
  const removeActive = (link) => menu_links[link].classList.remove("active");
  const removeAllActive = () => [...Array(sections.length).keys()].forEach((link) => removeActive(link));

  // change the active link a bit above the actual section
  // this way it will change as you're approaching the section rather
  // than waiting until the section has passed the top of the screen
  const sectionMargin = 200;

  // keep track of the currently active link
  // use this so as not to change the active link over and over
  // as the user scrolls but rather only change when it becomes
  // necessary because the user is in a new section of the page
  let currentActive = 0;

  // listen for scroll events
  window.addEventListener("scroll", () => {

    // check in reverse order so we find the last section
    // that's present - checking in non-reverse order would
    // report true for all sections up to and including
    // the section currently in view
    //
    // Data in play:
    // window.scrollY    - is the current vertical position of the window
    // sections          - is a list of the dom nodes of the sections of the page
    //                     [...sections] turns this into an array so we can
    //                     use array options like reverse() and findIndex()
    // section.offsetTop - is the vertical offset of the section from the top of the page
    //
    // basically this lets us compare each section (by offsetTop) against the
    // viewport's current position (by window.scrollY) to figure out what section
    // the user is currently viewing
    const current = sections.length - [...sections].reverse().findIndex((section) => window.scrollY >= section.offsetTop - sectionMargin ) - 1

    // only if the section has changed
    // remove active class from all menu links
    // and then apply it to the link for the current section
    if (current !== currentActive) {
      removeAllActive();
      currentActive = current;
      makeActive(current);
    }

    // Header bg/border
    if(window.scrollY < 100){
      $('#Header').removeClass("scrolled");
    }else{
      $('#Header').addClass("scrolled");
    }
  });
}, false);
